
import {
  ref,
  onMounted,
  watch,
  nextTick,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import ProductDetailedDescriptionMobile from '~/domains/main/components/ProductDetailedDescriptionMobile';

export default defineComponent({
  name: 'ProductPopularSliderMobile',
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ProductDetailedDescriptionMobile,
    Swiper,
    SwiperSlide,
  },
  setup(_, { emit }) {
    const swiperImage = ref(null);
    const swiperDescription = ref(null);
    const activeIndex = ref(0);
    const isSliderImagesInit = ref(false);
    const isSliderDescriptionInit = ref(false);
    const { $gsap } = useContext();

    const swiperImageOptions = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      on: {
        /* eslint-disable */
        init: function () {
          isSliderImagesInit.value = true;
        },
        /* eslint-enable */
      },
    };

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(max-width: 1023px)', () => {
        $gsap.fromTo(
          swiperImage.value.$el.querySelector('.swiper-wrapper'),
          {
            y: 80,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: swiperImage.value.$el,
              start: 'top 60%',
            },
          }
        );

        $gsap.fromTo(
          swiperDescription.value.$el.querySelector('.swiper-wrapper'),
          {
            y: 40,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: swiperDescription.value.$el,
              start: 'top 80%',
            },
          }
        );
      });
    };

    const swiperDescriptionOptions = {
      slidesPerView: 1,
      on: {
        /* eslint-disable */
        init: function () {
          isSliderDescriptionInit.value = true;
          animateOnScroll();
        },
        /* eslint-enable */
      },
    };

    const prevSlide = () => {
      swiperImage.value.$swiper.slidePrev();
    };

    const nextSlide = () => {
      swiperImage.value.$swiper.slideNext();
    };

    onMounted(() => {
      const $swiperImage = swiperImage.value.$swiper;
      const $swiperDescription = swiperDescription.value.$swiper;
      nextTick(() => {
        $swiperImage.controller.control = $swiperDescription;
        $swiperDescription.controller.control = $swiperImage;
      });

      $swiperImage.on('slideChange', () => {
        activeIndex.value = $swiperImage.realIndex;
      });
    });

    watch(activeIndex, (newValue) => {
      emit('onSlideChange', newValue);
    });

    return {
      swiperImage,
      swiperDescription,
      swiperImageOptions,
      swiperDescriptionOptions,
      activeIndex,
      isSliderImagesInit,
      isSliderDescriptionInit,
      prevSlide,
      nextSlide,
    };
  },
});
