import { render, staticRenderFns } from "./ProductPopularSlider.vue?vue&type=template&id=16fcbda8&scoped=true"
import script from "./ProductPopularSlider.vue?vue&type=script&lang=js"
export * from "./ProductPopularSlider.vue?vue&type=script&lang=js"
import style0 from "./ProductPopularSlider.vue?vue&type=style&index=0&id=16fcbda8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fcbda8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIImg: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIImg.vue').default,SliderFraction: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/SliderFraction.vue').default})
