
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import { breakpoint } from '~/composables/breakpoint.js';
import ProductPopularSlider from '~/domains/main/components/ProductPopularSlider';
import ProductPopularSliderMobile from '~/domains/main/components/ProductPopularSliderMobile';

export default defineComponent({
  name: 'MainProductsSection',
  components: { ProductPopularSliderMobile, ProductPopularSlider },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const popularSliderSection = ref();
    const button = ref();
    const { $gsap } = useContext();
    const { isBiggerThanBreakpoint } = breakpoint('xl');
    const activeIndex = ref(0);
    const activeLink = computed(() => props?.products[activeIndex.value].link);

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(min-width: 1024px)', () => {
        $gsap.fromTo(
          button.value.$el,
          {
            y: 80,
          },
          {
            y: 0,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: popularSliderSection.value.$el,
              start: '30% center',
            },
          }
        );
      });
    };

    onMounted(() => {
      animateOnScroll();
    });

    return {
      activeLink,
      activeIndex,
      isBiggerThanBreakpoint,
      popularSliderSection,
      button,
    };
  },
});
