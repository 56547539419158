
import { computed, defineComponent } from '@nuxtjs/composition-api';

const delayAnimation = [
  {
    delayBlock: 'delay-0',
    delayDotIn: 'delay-[300ms]',
    delayDotOut: 'delay-0',
  },
  {
    delayBlock: 'delay-[300ms]',
    delayDotIn: 'delay-[600ms]',
    delayDotOut: 'delay-[300ms]',
  },
  {
    delayBlock: 'delay-[600ms]',
    delayDotIn: 'delay-[900ms]',
    delayDotOut: 'delay-[600ms]',
  },
];

export default defineComponent({
  name: 'ProductDetailedDescription',
  props: {
    caption: {
      type: String,
      default: 'Detailed description',
    },
    lineWidth: {
      type: String,
      default: null,
    },
    circlePosition: {
      type: String,
      default: null,
    },
    isVisibleDetail: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const delayBlock = computed(() => {
      return delayAnimation[props.index].delayBlock;
    });

    const transition = computed(() => {
      return `transition-all duration-[500ms] ${delayBlock.value}`;
    });

    const transitionCircleIn = computed(() => {
      return `transition-all duration-[1000ms] ${
        delayAnimation[props.index].delayDotIn
      }`;
    });

    const transitionCircleOut = computed(() => {
      return `transition-all duration-[100ms] ${
        delayAnimation[props.index].delayDotOut
      }`;
    });

    return {
      transition,
      transitionCircleIn,
      transitionCircleOut,
      delayBlock,
    };
  },
});
