
import { ref, defineComponent, useContext } from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import ProductDetailedDescription from '~/domains/main/components/ProductDetailedDescription';

export default defineComponent({
  name: 'ProductPopularSlider',
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ProductDetailedDescription,
    Swiper,
    SwiperSlide,
  },
  setup(_, { emit }) {
    const swiper = ref(null);
    const { $gsap } = useContext();
    const activeIndex = ref(0);
    const activeIndexDetail = ref(0);
    const isVisibleDetail = ref(false);
    const delayBetweenChangeDetail = 1200;
    const descriptionPositions = [
      [
        {
          position: 'top-[127px] 2xl:top-[180px]',
          line_width: 'w-[332px] 2xl:w-[345px] 3xl:w-[409px]',
          circle_position: 'right-[332px] 2xl:right-[345px] 3xl:right-[409px]',
        },
        {
          position: 'top-[290px] 2xl:top-[410px]',
          line_width: 'w-[390px] 2xl:w-[415px] 3xl:w-[470px]',
          circle_position: 'right-[390px] 2xl:right-[415px] 3xl:right-[470px]',
        },
        {
          position: 'top-[483px] 2xl:top-[625px]',
          line_width: 'w-[550px] 2xl:w-[625px] 3xl:w-[688px]',
          circle_position: 'right-[550px] 2xl:right-[625px] 3xl:right-[688px]',
        },
      ],
      [
        {
          position: 'top-[139px] 2xl:top-[194px]',
          line_width: 'w-[422px] 2xl:w-[464px] 3xl:w-[528px]',
          circle_position: 'right-[422px] 2xl:right-[464px] 3xl:right-[528px]',
        },
        {
          position: 'top-[307px] 2xl:top-[400px]',
          line_width: 'w-[445px] 2xl:w-[501px] 3xl:w-[562px]',
          circle_position: 'right-[445px] 2xl:right-[501px] 3xl:right-[562px]',
        },
        {
          position: 'top-[463px] 2xl:top-[604px]',
          line_width: 'w-[318px] 2xl:w-[334px] 3xl:w-[402px]',
          circle_position: 'right-[318px] 2xl:right-[334px] 3xl:right-[402px]',
        },
      ],
      [
        {
          position: 'top-[100px] 2xl:top-[194px]',
          line_width: 'w-[329px] 2xl:w-[349px] 3xl:w-[414px]',
          circle_position: 'right-[329px] 2xl:right-[349px] 3xl:right-[414px]',
        },
        {
          position: 'top-[287px] 2xl:top-[400px]',
          line_width: 'w-[366px] 2xl:w-[379px] 3xl:w-[443px]',
          circle_position: 'right-[366px] 2xl:right-[379px] 3xl:right-[443px]',
        },
        {
          position: 'top-[458px] 2xl:top-[611px]',
          line_width: 'w-[393px] 2xl:w-[440px] 3xl:w-[507px]',
          circle_position: 'right-[393px] 2xl:right-[440px] 3xl:right-[507px]',
        },
      ],
      [
        {
          position: 'top-[142px] 2xl:top-[201px]',
          line_width: 'w-[648px] 2xl:w-[767px] 3xl:w-[827px]',
          circle_position: 'right-[648px] 2xl:right-[767px] 3xl:right-[827px]',
        },
        {
          position: 'top-[325px] 2xl:top-[434px]',
          line_width: 'w-[559px] 2xl:w-[662px] 3xl:w-[723px]',
          circle_position: 'right-[559px] 2xl:right-[662px] 3xl:right-[723px]',
        },
        {
          position: 'top-[486px] 2xl:top-[625px]',
          line_width: 'w-[384px] 2xl:w-[425px] 3xl:w-[488px]',
          circle_position: 'right-[384px] 2xl:right-[425px] 3xl:right-[488px]',
        },
      ],
    ];
    const isSliderInit = ref(false);
    const isActiveDelayProperty = ref(false);

    const swiperOptions = {
      slidesPerView: 'auto',
      autoHeight: true,
      effect: 'fade',
      simulateTouch: false,
      speed: 2000,
      fadeEffect: {
        crossFade: true,
      },
      on: {
        /* eslint-disable */
        init: function () {
          isSliderInit.value = true;
          animateOnScroll();
          setTimeout(() => {
            isActiveDelayProperty.value = true;
          }, 1000);
        },
        /* eslint-enable */
      },
    };

    const changeDetailState = () => {
      isVisibleDetail.value = false;
      setTimeout(() => {
        isVisibleDetail.value = true;
        activeIndexDetail.value = activeIndex.value;
      }, delayBetweenChangeDetail);
    };

    const showDetailState = () => {
      isVisibleDetail.value = true;
    };

    const changeSlide = (index) => {
      if (index === activeIndex.value) {
        return;
      }

      activeIndex.value = index;
      emit('onSlideChange', activeIndex.value);
      changeDetailState();
      swiper.value.$swiper.slideTo(index);
    };

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(min-width: 1024px)', () => {
        $gsap.fromTo(
          swiper.value.$el.querySelector('.swiper-wrapper'),
          {
            y: 80,
          },
          {
            y: 0,
            duration: 1.5,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: swiper.value.$el,
              start: 'top center',
            },
          }
        );

        $gsap.to(swiper.value.$el, {
          ease: 'power3.out',
          scrollTrigger: {
            trigger: swiper.value.$el,
            start: 'top center',
          },
          onComplete: () => {
            showDetailState();
          },
        });
      });
    };

    return {
      isSliderInit,
      isVisibleDetail,
      isActiveDelayProperty,
      swiperOptions,
      swiper,
      activeIndex,
      activeIndexDetail,
      descriptionPositions,
      changeSlide,
    };
  },
});
